<template>
  <div
    class="like-container"
    :class="{ 'disable-vote': isPostClosed }"
    @mouseover="isClapHovered = true"
    @mouseout="isClapHovered = false"
  >
    <div class="like-inner" @click="$emit('toggleLike')">
      <img
        :src="userVoted ? clapBlue : voteCount > 0 ? clapBlue : clapHover"
        alt="clap"
        v-if="isClapHovered"
      />
      <img
        :src="
          userVoted && !isPostClosed
            ? clapBlue
            : voteCount > 0
            ? clapGrey
            : clapDefault
        "
        alt="clap"
        v-else
      />
      <span class="like-count">{{ formatCount(voteCount) }}</span>
    </div>
  </div>
</template>
<script>
import countFormatter from "@/core/mixins/countFormatter.js";
export default {
  props: {
    voteCount: Number,
    userVoted: Boolean,
    isPostClosed: Boolean
  },
  mixins: [countFormatter],
  data() {
    return {
      clapDefault: require("@/assets/images/vle/forums/clap.svg"),
      clapGrey: require("@/assets/images/vle/forums/clap-grey.svg"),
      clapBlue: require("@/assets/images/vle/forums/clap-blue.svg"),
      clapHover: require("@/assets/images/vle/forums/clap-hover.svg"),
      isClapHovered: false
    };
  }
};
</script>

<style lang="scss" scoped>
.like-container {
  padding: 8px;
  border-radius: 100px;
  cursor: pointer;
  &:hover:not(.disable-vote) {
    background-color: $brand-primary-100;
    .like-count {
      color: $brand-primary-400;
    }
  }
  .like-inner {
    @include flex-horizontal-center;
    width: 100%;
    height: 100%;
  }
  .like-count {
    @include body-medium;
    color: $brand-neutral-700;
    margin-left: 8px;
  }
  &.disable-vote {
    opacity: 0.5;
    cursor: not-allowed;
    .like-inner {
      pointer-events: none;
    }
  }
}
[dir="rtl"] {
  .like-container {
    .like-count {
      margin-left: 0px;
      margin-right: 8px;
    }
  }
}
</style>
